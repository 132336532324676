import Paragraph from 'styleguide/typography/Paragraph'
import styled from 'styled-components'

import Button from 'elements/Button'
import Container from 'elements/Container'

export const AdvantagesContainer = styled.div`
  flex: 1;
  height: fit-content;
  display: flex;
  align-items: flex-end;
  ${({ theme }) => `
    padding-left: ${theme.spacing(11)}px;
    ${theme.breakpoints.down('sm')} {
      padding-left: 0;
    }
  `}
`

export const StyledFirstParagraph = styled(Paragraph)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(1)}px;
  `}
`

export const IconWrapper = styled.span`
  display: inline-block;
  width: auto;
  vertical-align: middle;
`

export const StyledParagraph = styled(Paragraph)`
  ${({ theme }) => `
    margin: ${theme.spacing(1, 0, 0, 0)};
    padding-bottom: ${theme.spacing(1)}px;
    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(0, 0, 0, 0)};
      padding-bottom: 0;
    }
  `}
`

export const StyledContainer = styled(Container)`
  max-width: 400px;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(3)}px;
    }
    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `}
`

export const StyledButton = styled(Button)`
  width: 320px;
  margin: 8px 0;
  font-size: 14px;
  padding: 8px 24px;
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `}
`
