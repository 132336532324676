import React from 'react'

import Row from 'elements/Row'
import Col from 'elements/Col'

import Advantages from './components/Advantages'
import LoginBox from 'components/Login/components/LoginBox'

import { LoginContainer, StyledCol } from './styles'
import usePreCacheCleanUp from './usePreCacheCleanUp'
import useCleanUpUserData from './useCleanUpUserData'
const MobileApplicationLinks = require('@commonds/react').MobileApplicationLinks

const Login = () => {
  // w sytuacji gdy user ma ciacha a nie jest zalogowany do systemu
  usePreCacheCleanUp()
  useCleanUpUserData()

  return (
    <>
      <LoginContainer>
        <Row alignContent='center' alignItems='center' justify='center'>
          <StyledCol sm={12} md='auto'>
            <LoginBox />
          </StyledCol>
          <Col sm={12} md='auto'>
            <Advantages />
          </Col>
        </Row>
      </LoginContainer>
      <MobileApplicationLinks />
    </>
  )
}

export default Login
