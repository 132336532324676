/* eslint-disable max-len */

import React from 'react'

const ActualPrize = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='48'
    height='48'
    fill='none'
    viewBox='0 0 48 48'
  >
    <rect
      width='27'
      height='38'
      x='43.5'
      y='10.5'
      stroke='#6AB880'
      strokeWidth='3'
      rx='4.5'
      transform='rotate(90 43.5 10.5)'
    />
    <path stroke='#6AB880' strokeWidth='3' d='M4.917 17.5L43.917 17.5' />
    <path stroke='#6AB880' strokeWidth='3' d='M4.917 23.5L43.917 23.5' />
    <path
      stroke='#6AB880'
      strokeLinecap='round'
      strokeWidth='3'
      d='M33.584 30.629L37 30.629'
    />
  </svg>
)

export default ActualPrize
