import styled from 'styled-components'

import Col from 'elements/Col'

export const LoginContainer = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(2)}px;
    }
  `}
`

export const StyledCol = styled(Col)`
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`
