import { useContext, useEffect } from 'react'
import { userContext } from 'context/UserProvider/initialState'

const useCleanUpUserData = () => {
  const { clearState } = useContext(userContext)

  useEffect(
    () => {
      clearState()
    },
    []
  )
}

export default useCleanUpUserData
