import React from 'react'

import OneLoginWP from 'elements/Icons/Login/OneLoginWP'

import Header from 'styleguide/typography/Header'

import { StyledContainer, StyledFirstParagraph, IconWrapper, StyledButton, StyledParagraph } from 'components/Login/components/styled'
import useLoginManagement from 'components/Login/components/LoginBox/hooks/useLoginManagement'

const LoginBox = () => {
  const { isButtonsDisabled, isLoginButtonLoading, handleClickLogin, handleClickRegister, isRegisterButtonLoading } = useLoginManagement()

  return (
    <StyledContainer>
      <Header headerVariant='h2' align='center'>
        Panel Klienta <br />
        Moje konto
      </Header>
      <StyledFirstParagraph size='m' align='center'>
        Logowanie z{' '}
        <IconWrapper>
          <OneLoginWP />
        </IconWrapper>
      </StyledFirstParagraph>
      <StyledButton
        size='large'
        themeVariant='reservation'
        onClick={handleClickLogin}
        disabled={isButtonsDisabled}
        isLoading={isLoginButtonLoading}
      >
        ZALOGUJ SIĘ
      </StyledButton>
      <StyledParagraph size='m' align='center'>
        lub
      </StyledParagraph>
      <StyledButton
        size='large'
        themeVariant='primary'
        onClick={handleClickRegister}
        disabled={isButtonsDisabled}
        isLoading={isRegisterButtonLoading}
      >
        Utwórz konto
      </StyledButton>
    </StyledContainer>
  )
}

export default LoginBox
