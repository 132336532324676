import styled from 'styled-components'
import Header from '../typography/Header'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${({ theme }) => `
    padding: ${theme.spacing(6, 2)};
    ${theme.breakpoints.down('sm')} {
      border-top: 1px solid #ccc;
      padding: ${theme.spacing(3, 0)};
    }
  `}
`

export const StyledHeader = styled(Header)`
  ${({ theme }) => `
    margin: ${theme.spacing(0, 0, 4)};
    ${theme.breakpoints.down('sm')} {
      ${theme.mobileMixins.headers.h3}
    }
  `}
`

export const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
