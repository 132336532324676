/* eslint-disable max-len */

import React from 'react'

const Favourites = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'>
    <path
      fill='#C9F'
      fillRule='evenodd'
      stroke='#C9F'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M19.94 7.327c-1.658-1.144-3.947-1.816-6.945-1.116-3.809.89-6.173 3.242-7.292 6.249-1.126 3.025-.999 6.742.262 10.317.489 1.384 1.511 2.948 2.883 4.583 1.366 1.628 3.056 3.302 4.85 4.906 3.588 3.21 7.564 6.118 10.127 7.813a.46.46 0 01.13.642.469.469 0 01-.648.128c-2.592-1.714-6.606-4.65-10.234-7.895-1.814-1.623-3.539-3.328-4.943-5.002-1.4-1.669-2.503-3.332-3.045-4.87-1.316-3.73-1.474-7.673-.257-10.943 1.223-3.288 3.83-5.867 7.954-6.83 3.261-.76 5.82-.032 7.69 1.259 1.855 1.28 3.014 3.098 3.532 4.514a.462.462 0 01-.279.593.468.468 0 01-.598-.277c-.463-1.264-1.514-2.915-3.187-4.07z'
      clipRule='evenodd'
    />
    <path
      fill='#C9F'
      fillRule='evenodd'
      stroke='#C9F'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M27.56 7.327c1.658-1.144 3.947-1.816 6.945-1.116 3.809.89 6.173 3.242 7.292 6.249 1.126 3.025.999 6.742-.262 10.317-.8 2.267-2.395 4.365-5.156 7.051a.46.46 0 00-.006.655.47.47 0 00.66.006c2.77-2.695 4.505-4.92 5.382-7.407 1.316-3.73 1.474-7.673.257-10.943-1.223-3.288-3.83-5.867-7.954-6.83-3.261-.76-5.82-.032-7.69 1.259-1.855 1.28-3.014 3.098-3.532 4.514a.462.462 0 00.279.593.468.468 0 00.598-.277c.463-1.264 1.514-2.915 3.187-4.07zm.386 30.303a.469.469 0 00-.653-.094 86.067 86.067 0 01-3.618 2.543.46.46 0 00-.13.642c.144.213.433.27.648.128a87.103 87.103 0 003.658-2.57.46.46 0 00.095-.649z'
      clipRule='evenodd'
    />
    <path
      fill='#EBD6FF'
      fillRule='evenodd'
      stroke='#C9F'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M23.883 24.007a.442.442 0 01.48-.06l15.917 7.731a.436.436 0 01-.018.791l-3.512 1.363a.673.673 0 00-.356.424.52.52 0 00.007.287c.03.114.103.218.145.267l4.13 5.083a.434.434 0 01-.034.601l-3.582 2.989a.442.442 0 01-.636-.036l-3.907-5.208a.682.682 0 00-.451-.229l-.12-.01a.686.686 0 00-.49.281l-1.65 2.993a.44.44 0 01-.782-.115l-5.274-16.69a.434.434 0 01.133-.462zm1.012 1.169l4.696 14.863 1.144-2.256a1.546 1.546 0 011.248-.654l.16.012c.402.036.775.225 1.04.529l.002.003 3.61 4.86 2.94-2.39s-3.7-4.588-3.856-4.766a1.507 1.507 0 01-.329-.616c-.125-.482-.01-.716-.01-.716.046-.303.21-.599.58-.733l2.92-1.265-14.145-6.871z'
      clipRule='evenodd'
    />
  </svg>
)

export default Favourites
