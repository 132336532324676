import { useState } from 'react'
import Router from 'next/router'

import toast from 'helpers/toast'
import runtimeConfig from 'common/config'

const { publicRuntimeConfig } = runtimeConfig

const useLoginManagement = () => {
  const [isButtonsDisabled, setIsButtonDisabled] = useState(false)
  const [isLoginButtonLoading, setIsLoginButtonLoading] = useState(false)
  const [isRegisterButtonLoading, setIsRegisterButtonLoading] = useState(false)

  const doProceed = (flow: string): void => {
    setIsButtonDisabled(true)
    const url = new URL(publicRuntimeConfig.ONE_LOGIN_AUTH_URL as string)
    url.searchParams.append('LoginRedirectUrl', publicRuntimeConfig.ONE_LOGIN_REDIRECT_URL as string)
    url.searchParams.append('ErrorRedirectUrl', publicRuntimeConfig.ONE_LOGIN_ERROR_REDIRECT_URL as string)
    url.searchParams.append('ssoCallback', publicRuntimeConfig.ONE_LOGIN_CALLBACK_URL as string)
    url.searchParams.append('force_select', '1') // @todo: usunac po wdrozeniu PWPK-858 w CuB
    url.searchParams.append('flow', flow)

    Router.push(url.toString())
      .catch(() => {
        toast.dismiss()
        setIsButtonDisabled(false)
        setIsLoginButtonLoading(false)
        setIsRegisterButtonLoading(false)
        toast.error('Wystąpił błąd', 'Nie powiodło się przekierowanie')
      })
  }
  const handleClickLogin = () => {
    toast.info('Przetwarzanie', 'Zaraz nastąpi przekierownie do panelu logowania')
    setIsLoginButtonLoading(true)
    doProceed(publicRuntimeConfig.ONE_LOGIN_FLOW_SELECT_ACCOUNT as string)
  }
  const handleClickRegister = () => {
    toast.info('Przetwarzanie', 'Zaraz nastąpi przekierownie do formularza rejestracyjnego')
    setIsRegisterButtonLoading(true)
    doProceed(publicRuntimeConfig.ONE_LOGIN_FLOW_REGISTRATION as string)
  }

  return {
    isButtonsDisabled,
    isLoginButtonLoading,
    isRegisterButtonLoading,
    handleClickLogin,
    handleClickRegister
  }
}

export default useLoginManagement
