/* eslint-disable max-len */

import React from 'react'

const Safety = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'>
    <path
      fill='#fff'
      d='M24.186 8.06l12.477 5.256V27.31a7.261 7.261 0 01-3.08 5.932l-9.317 6.66a.227.227 0 01-.185 0l-9.318-6.66c-1.921-1.379-3.08-3.59-3.08-5.932V13.316L24.187 8.06z'
    />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M24.187 7.368l13.17 5.546v14.41a7.972 7.972 0 01-3.381 6.51l-.002.001-9.308 6.652-.057.028a.988.988 0 01-.436.1.988.988 0 01-.436-.1l-.056-.028-9.313-6.654c-2.106-1.511-3.378-3.936-3.378-6.51v-14.41l13.197-5.545zM12.53 13.92v13.403c0 2.079 1.03 4.048 2.743 5.278l8.9 6.36 8.902-6.36.003-.003a6.456 6.456 0 002.739-5.275V13.92l-11.63-4.897L12.53 13.92z'
      clipRule='evenodd'
    />
    <path
      fill='#ED7566'
      fillRule='evenodd'
      stroke='#ED7566'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M23.698 2.308a.52.52 0 01.688-.263l16.306 7.273a.52.52 0 01.308.474v17.662c0 1.213-.253 3.164-1.006 5.138-.752 1.974-2.023 4.016-4.09 5.343-3.88 2.489-9.24 6.11-11.437 7.61a.52.52 0 01-.587-.857c2.198-1.502 7.569-5.13 11.46-7.627 1.82-1.167 2.977-2.992 3.682-4.839.704-1.847.937-3.672.937-4.768V10.13L23.961 2.994a.52.52 0 01-.263-.686z'
      clipRule='evenodd'
    />
    <path
      fill='#ED7566'
      fillRule='evenodd'
      stroke='#ED7566'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M24.302 2.308a.52.52 0 00-.688-.263L7.308 9.318A.52.52 0 007 9.792v17.662c0 1.213.253 3.164 1.006 5.138.752 1.974 2.023 4.016 4.09 5.343 3.88 2.489 9.24 6.11 11.437 7.61a.52.52 0 00.587-.857c-2.198-1.502-7.569-5.13-11.46-7.627-1.82-1.167-2.977-2.992-3.682-4.839-.704-1.847-.937-3.672-.937-4.768V10.13l15.998-7.135a.52.52 0 00.263-.686z'
      clipRule='evenodd'
    />
    <path
      fill='#ED7566'
      fillRule='evenodd'
      stroke='#ED7566'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M32.521 18.083a.519.519 0 010 .735L22.113 29.206a.521.521 0 01-.736 0l-5.898-5.887a.519.519 0 11.736-.735l5.53 5.52 10.04-10.022a.521.521 0 01.736 0z'
      clipRule='evenodd'
    />
  </svg>
)

export default Safety
