/* eslint-disable max-len */

import React from 'react'

const Passport = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='46'
    height='48'
    fill='none'
    viewBox='0 0 46 48'
  >
    <rect
      width='32.122'
      height='42'
      x='8.402'
      y='2.5'
      stroke='#0056BC'
      strokeWidth='3'
      rx='4.5'
    />
    <rect
      width='32.122'
      height='42'
      x='8.402'
      y='2.5'
      stroke='#fff'
      strokeOpacity='0.4'
      strokeWidth='3'
      rx='4.5'
    />
    <mask
      id='mask0_109_5758'
      style={{ maskType: 'luminance' }}
      width='35'
      height='45'
      x='7'
      y='1'
      maskUnits='userSpaceOnUse'
    >
      <path fill='#fff' d='M42 1H7v45h15c11.046 0 20-8.954 20-20V1z' />
    </mask>
    <g mask='url(#mask0_109_5758)'>
      <path
        fill='#0056BC'
        d='M24.213 10c-5.386 0-9.764 4.484-9.764 10s4.378 10 9.764 10c5.387 0 9.765-4.484 9.765-10S29.6 10 24.213 10zm6.836 8.564h-2.688c-.086-1.73-.336-3.412-.768-4.818a7.165 7.165 0 013.447 4.818h.01zm-8.19 2.852h2.708c-.173 3.097-.902 4.995-1.354 5.585-.45-.59-1.18-2.488-1.353-5.585zm0-2.852c.173-3.097.903-4.995 1.354-5.585.452.59 1.181 2.488 1.354 5.585H22.86zm-2.025-4.818c-.423 1.406-.682 3.078-.768 4.818h-2.688a7.187 7.187 0 013.446-4.818m-3.447 7.67h2.689c.086 1.73.336 3.412.768 4.818a7.164 7.164 0 01-3.447-4.818m10.216 4.818c.422-1.406.681-3.078.768-4.818h2.688a7.186 7.186 0 01-3.447 4.818h-.01z'
      />
      <path
        fill='#fff'
        fillOpacity='0.4'
        d='M24.213 10c-5.386 0-9.764 4.484-9.764 10s4.378 10 9.764 10c5.387 0 9.765-4.484 9.765-10S29.6 10 24.213 10zm6.836 8.564h-2.688c-.086-1.73-.336-3.412-.768-4.818a7.165 7.165 0 013.447 4.818h.01zm-8.19 2.852h2.708c-.173 3.097-.902 4.995-1.354 5.585-.45-.59-1.18-2.488-1.353-5.585zm0-2.852c.173-3.097.903-4.995 1.354-5.585.452.59 1.181 2.488 1.354 5.585H22.86zm-2.025-4.818c-.423 1.406-.682 3.078-.768 4.818h-2.688a7.187 7.187 0 013.446-4.818m-3.447 7.67h2.689c.086 1.73.336 3.412.768 4.818a7.164 7.164 0 01-3.447-4.818m10.216 4.818c.422-1.406.681-3.078.768-4.818h2.688a7.186 7.186 0 01-3.447 4.818h-.01z'
      />
      <path
        fill='#0056BC'
        d='M32.567 35H16.278a1.83 1.83 0 100 3.658h16.29a1.83 1.83 0 100-3.658z'
      />
      <path
        fill='#fff'
        fillOpacity='0.4'
        d='M32.567 35H16.278a1.83 1.83 0 100 3.658h16.29a1.83 1.83 0 100-3.658z'
      />
    </g>
  </svg>

)

export default Passport
