import React from 'react'

import Features from 'styleguide/Feauters/Features'

import { ActualPrize, Favourites, Passport, Safety, Opinion } from 'elements/Images/Login'
import { AdvantagesContainer } from './styled'

const featureList = [
  {
    icon: <Passport />,
    description: 'Twoje rezerwacje, dokumenty i szczegóły<br/>podróży – wszystko w jednym miejscu'
  },
  {
    icon: <ActualPrize />,
    description: 'Zawsze aktualny status wpłat i opcja płatności online za zamówienie'
  },
  {
    icon: <Safety />,
    description: 'Łatwe i bezpieczne logowanie jednym kliknięciem dzięki 1login od WP.pl'
  },
  {
    icon: <Favourites />,
    description: 'Oferty dodane do Ulubionych dostępne z&nbsp;dowolnego urządzenia, gdzie chcesz i kiedy chcesz!'
  },
  {
    icon: <Opinion />,
    description: 'Dodaj opinię o hotelu i wygraj bon wakacyjny na 2 000 zł.'
  }
]

const Advantages = () => (
  <AdvantagesContainer>
    <Features features={featureList} />
  </AdvantagesContainer>
)

export default Advantages
