import React from 'react'

import Paragraph from 'styleguide/typography/Paragraph'

import { IconWrapper, TextWrapper, Wrapper } from './styles'

import { FeatureData } from '../FeatureData'

interface Props extends FeatureData {
  isLastItem: boolean
}

const Feature: React.FC<Props> = ({
  icon,
  isLastItem,
  description = ''
}: Props) => (
  <Wrapper $isLastItem={isLastItem}>
    <IconWrapper>
      {icon}
    </IconWrapper>
    <TextWrapper>
      <Paragraph size='m' noPaddingBottom dangerouslySetInnerHTML={{ __html: description }} />
    </TextWrapper>
  </Wrapper>
)

export default Feature
