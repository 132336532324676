import styled from 'styled-components'

export const Wrapper = styled.div<{ $isLastItem: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 405px;
  margin-bottom: ${({ $isLastItem }) => ($isLastItem ? '0' : '32px')};
  ${({ theme, $isLastItem }) => `
    ${theme.breakpoints.down('sm')} {
      flex-direction: row;
      margin-bottom: ${$isLastItem ? '0' : '24px'};
    }
  `}
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const IconWrapper = styled.div`
  ${({ theme }) => `margin-right: ${theme.spacing(3)}px;`}
`
