import React from 'react'

import Feature from './Feature'

import { FeaturesWrapper, StyledHeader, Wrapper } from './styles'

import { FeatureData } from './FeatureData'

interface Props {
  features: FeatureData[]
  className?: string
  title?: string
}

const Features: React.FC<Props> = ({ features, title, className }: Props) => (
  <Wrapper className={className}>
    {
      title && (
        <StyledHeader
          headerVariant='h3'
        >
          {title}
        </StyledHeader>
      )
    }
    <FeaturesWrapper>
      {
        features.map(({ icon, description }, index) => (
          <Feature
            key={description}
            icon={icon}
            description={description}
            isLastItem={index === features.length - 1}
          />
        ))
      }
    </FeaturesWrapper>
  </Wrapper>
)

export default Features
