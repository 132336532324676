import { useEffect } from 'react'
import cleanUpPreCacheData from '../../helpers/cleanUpPreCacheData'

const usePreCacheCleanUp = () => {
  useEffect(
    () => {
      cleanUpPreCacheData()
    },
    []
  )
}

export default usePreCacheCleanUp
