/* eslint-disable max-len */

import React from 'react'

const Opinion = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'>
    <path
      stroke='#FFD024'
      strokeMiterlimit='10'
      strokeWidth='3.23'
      d='M35.916 41.992c-.319 0-.65-.079-.928-.224l-10.494-5.484-10.495 5.484a2 2 0 01-2.703-.83 1.915 1.915 0 01-.199-1.266l2.001-11.628-8.493-8.24a1.978 1.978 0 01-.027-2.821 1.977 1.977 0 011.14-.58l11.74-1.7 5.247-10.587a2 2 0 012.676-.91c.398.198.716.514.915.91l5.247 10.586 11.74 1.7a1.992 1.992 0 011.696 2.255 1.96 1.96 0 01-.583 1.134l-8.494 8.227 2.001 11.628a1.988 1.988 0 01-1.63 2.294 2.81 2.81 0 01-.344.026l-.014.026z'
    />
  </svg>
)

export default Opinion
